<template>
  <div data-theme="light" data-theme_color="#00E4A0">
    <div class="carregando" v-if="preload">
      <p class="mensagem-erro">
        Ocorreu um erro ao processar a sua solicitação.
      </p>
      <div>
        <img v-if="!isHeaven" src="@/assets/img/_logo.svg" alt="logo-loading" class="logo-loading" />
        <img v-else src="@/assets/img/heaven/logo.svg" alt="logo-loading" class="logo-loading" />
        <span class="loading-carregando" :class="{'heaven': isHeaven}"></span>
      </div>
    </div>
    <div>
      <Navbar />
      <b-container class="gray">
        <b-row
          class="pb-5"
          :class="{
            'justify-content-center': charge_success || charge_error,
            'pt-5': !isMobile,
            'pt-4': isMobile,
          }"
        >
          <b-col cols="12" md="8" order="2" order-md="1" class="pr-md-0">
            <b-overlay :show="loading" rounded="sm">
              <div class="Checkout" v-if="!charge_error && !charge_success">
                <div class="Checkout-title">
                  <span class="Checkout-title-number">01</span>
                  Dados Pessoais
                </div>

                <b-row>
                  <b-col cols="12">
                    <b-form-group label="Nome completo" label-for="name">
                      <b-form-input
                        type="text"
                        id="name"
                        v-model="data.name"
                        placeholder="Digite seu nome completo"
                        disabled
                        name="name"
                        :state="submit ? !errors.has('name') : null"
                        v-validate="'required'"
                        autocomplete="off"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        :state="submit ? !errors.has('name') : null"
                      >
                        Por favor, preencha seu nome.
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12">
                    <b-form-group label="CPF ou CNPJ" label-for="cpf_cnpj">
                      <b-form-input
                        id="cpf_cnpj"
                        v-model="data.cpf_cnpj"
                        placeholder="Documento do titular da compra"
                        disabled
                        type="text"
                        name="cpf_cnpj"
                        :state="submit ? !errors.has('cpf_cnpj') : null"
                        v-validate="'required|cpf_cnpj'"
                        v-mask="['###.###.###-##', '##.###.###/####-##']"
                        autocomplete="off"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        :state="submit ? !errors.has('cpf_cnpj') : null"
                      >
                        Por favor, preencha seu CPF ou CNPJ.
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>

                <div class="Checkout-title">
                  <span class="Checkout-title-number">02</span>
                  Pagamento
                </div>

                <b-row>
                  <b-col cols="12" md="6" id="dados-card">
                    <b-form-group label="Número" label-for="card_number">
                      <b-form-input
                        id="card_number"
                        v-model="data.card_number"
                        type="text"
                        placeholder="Somente números"
                        name="card_number"
                        :state="
                          submit
                            ? !(errors.has('card_number') || card_number_error)
                            : null
                        "
                        v-validate="'required|credit_card'"
                        v-mask="generateCardNumberMask"
                        v-on:focus="focusInput"
                        v-on:blur="blurInput"
                        data-ref="cardNumber"
                        autocomplete="off"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        :state="
                          submit
                            ? !(errors.has('card_number') || card_number_error)
                            : null
                        "
                      >
                        Por favor, preencha corretamente o número do cartão de
                        crédito.
                      </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group
                      label="Titular do cartão"
                      label-for="card_holder_name"
                    >
                      <b-form-input
                        id="card_holder_name"
                        v-model="data.card_holder_name"
                        type="text"
                        placeholder="Como no cartão"
                        name="card_holder_name"
                        :state="submit ? !errors.has('card_holder_name') : null"
                        v-validate="'required'"
                        v-on:focus="focusInput"
                        v-on:blur="blurInput"
                        data-ref="cardName"
                        autocomplete="off"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        :state="submit ? !errors.has('card_holder_name') : null"
                      >
                        Por favor, preencha corretamente o nome impresso no
                        cartão de crédito.
                      </b-form-invalid-feedback>
                    </b-form-group>

                    <b-row>
                      <b-col cols="12" md="8">
                        <b-row>
                          <b-col cols="12" md="6">
                            <b-form-group label="Mês" label-for="card_month">
                              <b-form-select
                                v-model="data.card_month"
                                class="mb-3"
                                id="card_month"
                                name="card_month"
                                v-validate="'required'"
                                :state="
                                  submit ? !errors.has('card_month') : null
                                "
                              >
                                <b-form-select-option value=""
                                  >Mês</b-form-select-option
                                >
                                <b-form-select-option
                                  v-bind:value="n < 10 ? '0' + n : n"
                                  v-for="n in 12"
                                  v-bind:disabled="n < minCardMonth"
                                  v-bind:key="n"
                                >
                                  {{
                                    10 > n ? "0" + n : n
                                  }}</b-form-select-option
                                >
                              </b-form-select>
                              <b-form-invalid-feedback
                                :state="
                                  submit ? !errors.has('card_month') : null
                                "
                              >
                                Por favor, escolha o mês de validade.
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </b-col>

                          <b-col cols="12" md="6">
                            <b-form-group label="Ano" label-for="card_year">
                              <b-form-select
                                v-model="data.card_year"
                                class="mb-3"
                                id="card_year"
                                name="card_year"
                                v-validate="'required'"
                                :state="
                                  submit ? !errors.has('card_year') : null
                                "
                              >
                                <b-form-select-option value=""
                                  >Ano</b-form-select-option
                                >
                                <b-form-select-option
                                  v-bind:value="$index + minCardYear"
                                  v-for="(n, $index) in 12"
                                  v-bind:key="n"
                                >
                                  {{ $index + minCardYear }}
                                </b-form-select-option>
                              </b-form-select>
                              <b-form-invalid-feedback
                                :state="
                                  submit ? !errors.has('card_year') : null
                                "
                              >
                                Por favor, escolha o ano de validade.
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-col>

                      <b-col cols="12" md="4">
                        <b-form-group label="CVV" label-for="card_cvv">
                          <b-form-input
                            id="card_cvv"
                            v-model="data.card_cvv"
                            type="text"
                            name="card_cvv"
                            :state="submit ? !errors.has('card_cvv') : null"
                            v-validate="'required'"
                            maxlength="4"
                            v-mask="'####'"
                            v-on:focus="flipCard(true)"
                            v-on:blur="flipCard(false)"
                            autocomplete="off"
                          ></b-form-input>
                          <b-form-invalid-feedback
                            :state="submit ? !errors.has('card_cvv') : null"
                          >
                            Por favor, preencha corretamente o número CVV do
                            cartão de crédito.
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>

                  <b-col cols="12" md="6" id="_card">
                    <!-- Credit card -->
                    <card
                      ref="card"
                      :card_number="data.card_number"
                      :card_holder_name="data.card_holder_name"
                      :card_month="String(data.card_month)"
                      :card_year="String(data.card_year)"
                      :card_cvv="String(data.card_cvv)"
                    />
                  </b-col>
                </b-row>

                <b-row class="mt-2 justify-content-end">
                  <b-col cols="12">
                    <b-button
                      class="btn-checkout btn-assinatura"
                      :disabled="loading"
                      type="button"
                      variant="primary"
                      @click="onSubmit()"
                      >Atualizar Assinatura</b-button
                    >
                    <div class="mt-4 d-flex align-items-center">
                      <font-awesome-icon icon="lock" class="icon-lock" />
                      <p class="m-0 ml-3">
                        Informações seguras e criptografadas
                      </p>
                    </div>
                  </b-col>
                </b-row>
              </div>

              <div class="Checkout-footer-brands">
                <div
                  class="footer-safe"
                  v-if="!charge_error && !charge_success"
                >
                  <div class="item-safe">
                    <img
                      src="@/assets/img/icon/safe_lock.svg"
                      alt="safe_lock"
                    />
                    <p>Compra</p>
                    <p class="destaque-info _lock">SEGURA</p>
                  </div>
                  <div class="item-safe">
                    <img
                      src="@/assets/img/icon/safe_product.svg"
                      alt="safe_product"
                    />
                    <p>Produto</p>
                    <p class="destaque-info _product">REVISADO</p>
                  </div>
                  <div class="item-safe">
                    <img
                      src="@/assets/img/icon/safe_dados.svg"
                      alt="safe_dados"
                    />
                    <p>Dados</p>
                    <p class="destaque-info _dados">PROTEGIDOS</p>
                  </div>
                </div>
                <p class="p-footer" v-if="!isMobile">
                  *Parcelamento com tarifa de 2.92% a.m
                </p>
                <div class="cards_type" v-if="!isMobile">
                  <img v-if="!isHeaven" src="@/assets/img/logo2.png" class="icon-footer" alt="icon-greenn" />
                  <img v-else src="@/assets/img/heaven/logo.svg" class="icon-footer" alt="icon-greenn" />
                  <img
                    :src="getImg('visa')"
                    class="icon-footer"
                    alt="icon-visa"
                  />
                  <img
                    :src="getImg('mastercard')"
                    class="icon-footer"
                    alt="icon-mastercard"
                  />
                  <img
                    :src="getImg('hipercard-v2')"
                    class="icon-footer"
                    alt="icon-hipercard"
                  />
                  <img
                    :src="getImg('diners')"
                    class="icon-footer"
                    alt="icon-diners"
                  />
                  <img
                    :src="getImg('elo')"
                    class="icon-footer"
                    alt="icon-elo"
                  />
                  <img
                    :src="getImg('boleto-barcode')"
                    class="icon-footer"
                    alt="icon-boleto"
                  />
                </div>
              </div>

              <b-row
                class="Checkout-footer"
                v-if="!charge_error && !charge_success"
              >
                <div class="info-termos">
                  <p class="p-footer">
                    <!-- Greenn está processando este pedido a serviço de
                    {{
                      product_type == "COMMON"
                        ? product.seller.name
                        : product.product.seller.name
                    }} -->

                    <!-- , ao continuar, você concorda com os Termos de Compra -->
                  </p>
                  <p class="p-footer mb-1" v-if="isMobile">
                    *Parcelamento com tarifa de 2.92% a.m
                  </p>
                  <p class="p-footer">
                    Greenn © {{ ano_atual }} - Todos os direitos reservados
                  </p>
                </div>

                <!-- <b-col cols="12" md="4" class="mt-2 mt-md-0 text-right">
                  <div class="Checkout-footer-safe">
                    <font-awesome-icon icon="shield-alt" />
                    Ambiente 100% seguro
                  </div>
                </b-col> -->
              </b-row>

              <!-- Confirmation -->
              <div v-if="charge_success">
                <div class="wrapper-box success">
                  <b-row class="justify-content-center my-5">
                    <div class="icon">
                      <font-awesome-icon icon="check" />
                    </div>
                  </b-row>
                  <b-row>
                    <b-col cols="12">
                      <h5 class="text-center">
                        Seus dados de pagamento foram atualizados com sucesso!
                      </h5>
                    </b-col>
                  </b-row>
                </div>
              </div>

              <!-- Failed -->
              <div v-if="charge_error">
                <div class="wrapper-box failed">
                  <b-row class="justify-content-center my-5">
                    <div class="icon">
                      <font-awesome-icon icon="times" />
                    </div>
                  </b-row>
                  <h5 class="text-center">{{ error_message }}</h5>
                </div>
              </div>
            </b-overlay>
          </b-col>

          <b-col
            cols="12"
            md="4"
            order="1"
            order-md="2"
            class="mb-3 mb-md-0 p-3 p-md-0"
            v-if="!charge_error && !charge_success"
          >
            <div class="Product" :class="{ 'product-desktop': !isMobile }">
              <div class="Product-header">
                <font-awesome-icon icon="shield-alt" class="mr-2" />
                Compra 100% Segura
              </div>
              <div class="content-card">
                <div class="imagem-produto" v-if="product_type == 'PROPOSAL'">
                  <img
                    v-if="product.product.images.length"
                    :src="product.product.images[0].path"
                    class="product-img"
                    alt="image-product"
                  />
                  <div v-else class="product-img"></div>
                </div>

                <div class="imagem-produto" v-if="product_type == 'COMMON'">
                  <img
                    v-if="product.images.length"
                    :src="product.images[0].path"
                    alt="product-image"
                    class="product-img"
                  />
                  <div v-else class="product-img"></div>
                </div>

                <div class="info-card">
                  <div class="Product-name_2">{{ product.name }}</div>

                  <div class="Product-price_2">
                    <!-- v-if="product.type == 'SUBSCRIPTION'" -->
                    R$ {{ formatMoney(product.amount) }}
                    <div
                      class="item-info mt-2"
                      v-if="isMobile && product_type == 'COMMON'"
                    >
                      <p>
                        <span>Autor: </span>
                        {{
                          product.seller.company
                            ? product.seller.company.name
                            : product.seller.name
                        }}
                      </p>
                    </div>
                    <div
                      class="item-info mt-2"
                      v-if="isMobile && product_type == 'PROPOSTAL'"
                    >
                      <p>
                        <span>Autor: </span>
                        {{
                          product.product.seller.company
                            ? product.product.seller.company.name
                            : product.product.seller.name
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <b-row class="infos-mais">
                <p class="tipo-produto pl-3 mt-3">
                  Atualização dos dados de pagamento
                </p>
                <b-col cols="12" v-if="!isMobile">
                  <!-- <div class="item-info">
                    <div class="info-title">Detalhes</div>
                    <p>{{ product.description | convertText }}</p>
                  </div> -->

                  <div class="item-info" v-if="product_type == 'COMMON'">
                    <div class="info-title">Autor</div>
                    <p>
                      {{
                        product.seller.company
                          ? product.seller.company.name
                          : product.seller.name
                      }}
                    </p>
                  </div>
                  <div class="item-info" v-if="product_type == 'PROPOSAL'">
                    <div class="info-title">Autor</div>
                    <p>
                      {{
                        product.product.seller.company
                          ? product.product.seller.company.name
                          : product.product.seller.name
                      }}
                    </p>
                  </div>
                  <!-- <div class="item-info">
                    <div class="info-title">Suporte</div>
                    <p>
                      {{
                        product_type == "COMMON"
                          ? product.seller.email
                          : product.product.seller.email
                      }}
                    </p>
                  </div> -->
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>
import Navbar from "@/components/navbar/index.vue";
import Card from "@/components/Checkout/Card";
import pagarme from "@/assets/js/pagarme.min";
import Money from "@/mixins/money";

import PaymentService from "@/services/resources/PaymentService";
const servicePayment = PaymentService.build();

import ProductService from "@/services/resources/ProductService";
const serviceProduct = ProductService.build();

import ProposalService from "@/services/resources/ProposalService";
const serviceProposal = ProposalService.build();

import { datadogLogs } from "@datadog/browser-logs";

import errorPayment from "@/utils/errorPayment.js";
import { sanitizeUrl } from '@/utils/url.js';

const serviceGatewayHash = GatewayHashService.build();
import GatewayHashService from '@/services/resources/GatewayHashService';


datadogLogs.init({
  applicationId: "ac47dde0-0af0-43dc-a387-4f688be495d9",
  clientToken: "pub50c6ea627df0b5ed0776aa9a784b95a6",
  site: "datadoghq.com",
  service: "greenn-pay",
  env: "production",
  version: "1.5.0",
  sampleRate: 100,
  trackInteractions: true,
});

export default {
  components: {
    Card,
    Navbar,
  },
  data() {
    return {
      preload: false,
      data: {
        client_id: "",
        name: "",
        cpf_cnpj: "",
        card_number: "",
        card_holder_name: "",
        card_year: "",
        card_month: "",
        card_cvv: "",
      },
      submit: false,
      charge_success: false,
      charge_error: false,
      error_message: "",
      card_number_error: false,
      loading: false,
      amexCardMask: "#### ###### #####",
      otherCardMask: "#### #### #### ####",
      minCardYear: new Date().getFullYear(),
      product_type: "COMMON",
      product: {
        images: [{ path: "" }],
        name: "",
        description: "",
        seller: {
          name: "",
        },
      },
      client: {
        width: 0,
      },
      dataLog: {},
    };
  },
  mixins: [Money],
  computed: {
    ano_atual() {
      return new Date().getFullYear();
    },
    generateCardNumberMask() {
      return this.getCardType === "amex"
        ? this.amexCardMask
        : this.otherCardMask;
    },
    minCardMonth() {
      if (this.cardYear === this.minCardYear) return new Date().getMonth() + 1;
      return 1;
    },
    isMobile() {
      // return this.client.width <= 576;
      return this.client.width <= 769;
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  },

  methods: {
    removePreload() {
      // console.log("removendo preload");
      // setTimeout(() => {
      try {
        if (this.product) {
          this.preload = false;
          document.querySelector("body").classList.remove("preload");
        } else {
          document.querySelector(".carregando").classList.add("erro");
        }
      } catch {
        this.preload = false;
        document.querySelector("body").classList.remove("preload");
      }
      // }, 5000);
    },
    handleResize() {
      this.client.width = window.innerWidth;
    },
    getImg(img) {
      return require(`@/assets/img/footer/${img}.svg`);
    },
    flipCard(status) {
      this.$refs.card.flipCard(status);
    },
    focusInput(e) {
      this.$refs.card.focusInput(e);
    },
    blurInput() {
      this.$refs.card.blurInput();
    },
    onSubmit(scope) {
      this.submit = true;

      this.$validator.validateAll(scope).then(async (result) => {
        if (result) {
          this.loading = true;
          this.preload = true;

          let card = {
            card_number: this.data.card_number,
            card_holder_name: this.data.card_holder_name,
            card_expiration_date:
              String(this.data.card_month) + "/" + String(this.data.card_year),
            card_cvv: this.data.card_cvv,
          };

          var cardValidations = pagarme.validate({ card: card });

          // Check if the card number is valid
          if (!cardValidations.card.card_number) {
            this.card_number_error = true;
            this.loading = false;
            return;
          }

          // Success, so send the data to the server in order
          // to make the transaction
          var data = {
            client_id: +this.data.client_id,
            product_type: this.product_type,
            amount: this.product.amount,
            cpf_cnpj: this.data.cpf_cnpj,
            cards: [
              {
                card_number: this.data.card_number,
                card_holder_name: this.data.card_holder_name,
                card_expiration_date:
                  String(this.data.card_month) + String(this.data.card_year),
                card_cvv: this.data.card_cvv,
              },
            ],
          };

          if (this.product_type == "COMMON") {
            data.id = this.product.id;
          } else if (this.product_type == "PROPOSAL") {
            data.product_id = this.product.product.id;
            data.id = this.product.id;
          }

          // console.log("data-", data);

          // registrando log do objeto com os dados de update
          this.dataLog = Object.assign(
            {
              cartoes: {
                cvv: "",
                holder_name: "",
                expiration_date: "",
                number: "",
              },
            },
            data
          );

          delete this.dataLog.cards;
          this.dataLog.cartoes.cvv = "###";
          this.dataLog.cartoes.holder_name = data.cards[0].card_holder_name;
          this.dataLog.cartoes.expiration_date =
            data.cards[0].card_expiration_date;
          this.dataLog.cartoes.number = `#### #### #### ${
            data.cards[0].card_number.split(" ")[
              data.cards[0].card_number.split(" ").length - 1
            ]
          }`;

          datadogLogs.logger.info("🟡 Dados update", {
            name: "Enviando objeto do update",
            objetoUpdate: JSON.stringify(this.dataLog),
          });



          
          let promises = [];
          // Iterar sobre cada cartão e criar uma promessa para cada um
          let errorRequestCard = false;
          if(data.cards ){
            let gateway = this.getGateway(this.product.type, 'CREDIT_CARD', this.product.global_settings, null, this.product.seller.recipientIsActivated);
            if (gateway) {
              data.gateway = gateway;
            }
              for (let i = 0; i < data.cards.length; i++) {
                if (
                  data.cards[i] &&
                  data.cards[i].card_number &&
                  data.cards[i].card_holder_name &&
                  data.cards[i].card_expiration_date &&
                  data.cards[i].card_number != undefined
                ) {
                  let amount = data.cards[i].amount; // Armazenar o valor do campo amount
                  let total = data.cards[i].total; // Armazenar o valor do campo total

                
                  
                  let dataGateway = {
                    system: 'CHECKOUT',
                    gateway: gateway,
                    card: {
                      holder_name:  data.cards[i].card_holder_name,
                      number:  data.cards[i].card_number.replace(/\s/g, ''),
                      exp_month:   data.cards[i].card_expiration_date ? data.cards[i].card_expiration_date.substring(0, 2) : null ,
                      exp_year: data.cards[i].card_expiration_date.substring(4),
                      cvv: data.cards[i].card_cvv,
                      costumer: this.customerData(data)
                    }
                  };
                  
                  // Criar a promessa e armazená-la no array de promessas
                    let promise = this.cardGateway(dataGateway).then(responseGateway => {
                    // Atualizar o objeto data.cards[i] mantendo os campos amount e total
                      if (data.cards) {
                        data.cards[i] = {
                          id: responseGateway.id,
                          last_digits: responseGateway.data ? responseGateway.data.last_digits : responseGateway.last_digits,
                          first_digits: responseGateway.data ? responseGateway.data.first_digits : responseGateway.first_digits,
                          customer: responseGateway.customer || null, amount,
                          total
                        };
                      }
                  })
                  .catch(error => {
                    // Tratar erros
                    errorRequestCard = true;
                    console.error(error);
                  });        
                  promises.push(promise);
                }
              }
            }

                // Aguardar a resolução de todas as promessas usando Promise.all()
                return Promise.all(promises)
                  .then(() => {
                    if(!errorRequestCard){
                      this.updatePayment(data);
                   }
                     
                    
                  })
                  .catch(error => {
                      // Se ocorrer um erro em qualquer uma das promessas ou na criação do pagamento, ele será capturado aqui
                      console.error("Erro:", error);
                      throw error; // Lança novamente o erro para a função que contém este código
                });

          
        }
      });
    },
    updatePayment(data){
      servicePayment
            .update(data)
            .then((response) => {
              if (response.sales.length) {
                if (response.sales[0].success) {
                  this.charge_success = true;

                  // registrando log de sucesso
                  datadogLogs.logger.info("🟢 Success Update", {
                    name: "Atualização concluída com sucesso",
                    product_id: this.product.id,
                  });
                } else {
                  this.validateError(response.sales[0]);
                }
              } else if (!response.sales[0].success) {
                this.validateError(response.sales[0]);
              }
            })
            .catch((error) => {
              this.charge_error = true;
              this.error_message = this.$t('error.INVALID')
              // registrando log de sucesso
              datadogLogs.logger.info("🔴  Error Update", {
                name: "Erro na atualização",
                product_id: this.product.id,
                error_mensage: this.error_message,
              });
            })
            .finally(() => {
              this.loading = false;
              this.submit = false;
              this.preload = false;
            });
    },
    validateError(response) {
      this.charge_error = true;

      this.error_message = errorPayment(response.code);

      // registrando log de error
      datadogLogs.logger.info("🔴  Error Update", {
        name: "Erro na atualização",
        product_id: this.product.id,
        error_code: response.code,
        error_mensage: this.error_message,
      });
    },
    getProduct(slug) {
      return new Promise((resolve) => {
        let data = {
          id: slug,
        };

        serviceProduct
          .read(data)
          .then((response) => {
            this.product = response.data;
            this.product_type = response.type;
            document.title = this.product.name;
          })
          .then(() => {
            resolve(true);
          })
          .catch((err) => {
            console.log(err);
            resolve(true);
          });
      });
    },
    getProposal(id_proposta) {
      let data = {
        id: id_proposta,
      };
      serviceProposal
        .read(data)
        .then((response) => {
          if (response.id) {
            this.product_type = "PROPOSAL";
            this.product = response;
            document.title = this.product.name;
          } else {
            this.product = [];
            document.querySelector(".carregando").classList.add("erro");
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.removePreload();
        });
    },
    getGateway(type, metodo,global_settings, installments , recipientIsActivated) {
      
      //concatena type com method tranformando em letras Maiúsculas
      let gatewayKey = `${type.toUpperCase()}_${metodo.toUpperCase()}`;
      let result = "";

      switch (gatewayKey) {
        case 'TRANSACTION_CREDIT_CARD':
        case 'SUBSCRIPTION_CREDIT_CARD':
        case 'TRANSACTION_TWO_CREDIT_CARDS':
        case 'SUBSCRIPTION_TWO_CREDIT_CARDS':
        case 'TRANSACTION_BOLETO':
        case 'SUBSCRIPTION_BOLETO':
          result = 'PAGARME';
        case 'TRANSACTION_PIX':
        case 'SUBSCRIPTION_PIX':
          result = 'IUGU';
        case 'SUBSCRIPTION_CREDIT_CARD_DLOCAL':
        case 'TRANSACTION_CREDIT_CARD_DLOCAL':
          result = 'DLOCAL';
        default:
          result = "";
      }

      if (global_settings && global_settings.length > 0) {
        // Faz um find dentro das configurações globais
          const databaseConfiguration = global_settings.find(config => config.key === gatewayKey);

          // Se encontrar, retorna o valor correspondente
          if (databaseConfiguration) {
            result = databaseConfiguration.value;
          }
      

          const gatewayLowInstallment = global_settings.find(config => config.key === 'TRANSACTION_CREDIT_CARD_LOW_INSTALLMENT');
          let valuegatewayLowInstallment = null;

          if(gatewayLowInstallment){
            valuegatewayLowInstallment = gatewayLowInstallment.value;

            if(installments && installments <=2){
              // Se encontrar, retorna o valor correspondente
              if (gatewayLowInstallment) {
                result = gatewayLowInstallment.value;
              }
            }

            if(result == 'PAGARME' && !recipientIsActivated && valuegatewayLowInstallment == 'IUGU'){
              result = 'IUGU';
            }
          }
      }

      if(result == ''){
        this.$bvToast.toast(this.$t("global_settings.error"), {
              title: this.$t("global_settings.title"),
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
      }

      return result;
    },
    async cardGateway(dataGateway) {
      try {
        const response = await serviceGatewayHash.card(dataGateway);
        return response;
      } catch (error) {
        // Tratar erros
        if(error.data.type === 'INVALID_CREDIT_CARD'){
          this.$bvToast.toast("Não foi possível completar sua solicitação, configura seus dados ou tente novamente mais tarde.", {
            title: "",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        }else{
          this.$bvToast.toast("Ocorreu um erro ao processar a sua solicitação. Por favor tente novamente mais tarde.", {
            title: "",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        }
        throw error; // Lançar o erro novamente para que ele possa ser tratado onde a função cardGateway() foi chamada
      }
    },
    documentType(data) {
      switch (this.country_code) {
        case 'BR':
          return data.cpf_cnpj.length > 14 ? 'cnpj' : 'cpf';
        case 'MX':
          return 'RCF';
        case 'CL':
          return 'RUT';
        case 'UY':
          return 'CI';
        case 'ES':
          return 'DNI';
        case 'AR':
          switch (data.cpf_cnpj.length) {
            case 8:
              return 'DNI';
            case 11:
              return 'CUIT/CUIL';
          }
        default:
          return null;
    }
    },
    customerData(data) {
      let document_number = data.cpf_cnpj.replace(/\D/g, '');
      let document_type = this.documentType(data);
      let zipcodeFormatted = data.zipcode ? data.zipcode.slice(0, 5) + '-' + data.zipcode.slice(5) : null;

      let document = {};
          if (document_number) {
              document = {
                  document: {
                      type: document_type,
                      number: document_number,
                      document_type: document_type,
                      document_number: document_number
                  }
              };
          }

          let address = {};
          if (data.city) {
              address = {
                  address: {
                      street: data.street,
                      street_number: data.number,
                      state: data.state || data.uf,
                      city: data.city,
                      neighborhood: data.neighborhood,
                      zipcode: zipcodeFormatted
                  }
              };

              if (this.language && this.language !== 'pt' && this.country_code !== 'BR') {
                  delete address.address.zipcode;
              }
          }


          return {
              external_id: this.data.client_id,
              name:  "Uppate Subscript",
              phone: "99999999999",
              email: "updatesubscript@greenn.com",
              document_number: document_number,
              document_type: document_type,
              ...document,
              ...address
          };
    }
  },
  created(){
    sanitizeUrl(this.$route);
  },
  mounted() {
    document.querySelector("body").classList.add("preload");
    this.preload = true;

    const query = this.$route.query;
    this.data.name = query.fn;
    this.data.cpf_cnpj = query.document;
    this.data.client_id = query.client_id;

    const slug = this.$route.params.offer ? this.$route.params.product + '/offer/' + this.$route.params.offer : this.$route.params.product;
    if (slug && !query.prop_id) {
      this.getProduct(slug).then(() => this.removePreload());
    } else if (typeof query.prop_id != "undefined") {
      this.getProposal(query.prop_id);
    }

    // this.removePreload();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
};
</script>
<style scoped>
.btn-assinatura {
  width: 100%;
}
.icon-lock {
  color: #00af7b;
  /* color: #00a650; */
}
</style>